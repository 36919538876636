import React from 'react';
import { Typography, Paper, Grid, Box, Card, CardContent } from '@mui/material';
import GppBadIcon from '@mui/icons-material/GppBad';
import GppGoodIcon  from "@mui/icons-material/GppGood";
import tokenData from '../types/token';
import BigNumber from 'bignumber.js';

interface TokenSecurityProps {
  tokenData: tokenData;
  chain: string;
  address: string;
  trustScore: string;
}

const TokenSecurity: React.FC<TokenSecurityProps> = ({ tokenData, chain, address, trustScore }) => {

  function checkobj(obj:any){
    const checks = ['Pancake', 'Trader Joe', 'Uniswap', 'Sushiswap']
    const deadaddress = "0x000000000000000000000000000000000000dead"
    
    checks.forEach((ob:any)=>{
      console.log(obj)
      if (checks.includes(obj.address) || obj.address === deadaddress){
        return false
      }
    })
  
    return true
  }

  function analyzeHolders(holders: any[]): number[] {
    let flags = [0,0] 
    let total = 0
  
      for (const obj of holders) {
        if ((parseFloat(obj.percent) > 0.1) && !checkobj(obj)) {
          flags[1] = 1
        }
        else{
          total += parseFloat(obj.percent)
  
        }
      }
  
      if (total > 32.9){
        flags[0] = 1
      }
      console.log(total)
      return flags
    }
  
  function analyzeBigHolders(holders: any[]): number[] {
    let flags = [0,0]
    let total = 0
    for (const obj of holders) {
      if (obj.label !== 'Radium AMM (LP)') {
        if (obj.value >= 10){
          flags[1] = 1
        }
        total += obj.value
      }
    }
    if (total > 32.9){
      flags[0] = 1
    }

    return flags
  }
  
  function badLiquidity(ratio:number){
    return ratio > 1e-4;
  }
  
  function totalLiquidity(arr:tokenData){
      let liq = 0
      for (let i = 0; i < arr.liquidity.length; i++) {
        liq += parseInt(arr.liquidity[i].liquidity)
      }
      return liq
  }

  const holdersWithPercentage = (topHolders: any[], totalSupplyString: string, decimals: number) => {
    const totalSupply = new BigNumber(totalSupplyString);
    const res = topHolders.map((holder, i) => {
      const amount = new BigNumber(holder.amount);
      const ratio = amount.dividedBy(totalSupply);
      const percentage = ratio.multipliedBy(new BigNumber(10).exponentiatedBy(decimals)).multipliedBy(100);
      return {
        id: i,
        value: percentage.toNumber(),
        label: holder.address === '5Q544fKrFoe6tsEbD7S8EmxGTJYAKtTVhAW5Q5pge4j1' ? "Radium AMM (LP)" : holder.address,
        // Round to 6 decimal places
        percentageString: percentage.toFixed(decimals + 2)
      };
    });
    return res;
  };

  const getColorFromGradient = (score:number) => {
    const hue = (score / 100) * 120; // Convert score to a hue value between 0 and 120
    return `hsl(${hue}, 100%, 50%)`; // Use the hue value to create an HSL color
  };
  
  
  const auditItems = tokenData && chain != "solana"  && tokenData.result
  ? Object.entries({

    ownerPercentage:tokenData.result.owner_address !== undefined ? tokenData.result? tokenData.result?.owner_percent < 5 
      ? 'Owner does not hold a significant percentage of tokens' 
      : 'Owner holds a significant percentage of tokens'
      : ''
      : undefined,

    topHolders: tokenData.result!.holders && tokenData.result!.holders.length > 0? analyzeHolders(tokenData.result!.holders)[0] === 0 
      ? 'Top 10 do not hold a significant percentage of tokens' 
      : 'Top 10 hold a significant percentage of tokens'
      : '',

    liquidityPool: totalLiquidity(tokenData) < 5000 ? totalLiquidity(tokenData) < 1 ? 'Empty liquidity pool'  : 'Low liquidity pool' :'Liquidity pool is sufficient',

    significantHolder: tokenData.result!.holders && tokenData.result!.holders.length > 0?  analyzeHolders(tokenData.result!.holders)[1] === 0 
      ? 'No significant whales' 
      : 'Significant whale could have large price impact'
      : '',
  })  .filter(([key, value]) => value !== undefined && value !== null && value !== '')
  .map(([key, value]) => ({
      key,
      value,
      isWarning: ['Avg. Liquidity ratio may be insufficient for amount of trades', 'Low liquidity pool', 'Significant whale could have large price impact'].includes(value!),
      isDanger: ['Empty liquidity pool','Owner holds a significant percentage of tokens', 'Top 10 hold a significant percentage of tokens'].includes(value!),
    })).filter(item => item.value != '')
    .sort((a, b) => {
      if (a.isDanger && !b.isDanger) return -1;
      if (!a.isDanger && b.isDanger) return 1;
      if (a.isWarning && !b.isWarning) return -1;
      if (!a.isWarning && b.isWarning) return 1;
      return 0;
    })
  : tokenData && tokenData.solres
  ? Object.entries({
    ownerPercentage: (BigNumber(tokenData.solres.creatorBalance).dividedBy(BigNumber(tokenData.solres.supply))).multipliedBy(new BigNumber(10).exponentiatedBy(tokenData.solres.decimals)).multipliedBy(100).isLessThan(5) ? 'Owner does not hold a significant percentage of tokens' : 'Owner holds a significant percentage of tokens',
    topHolders: analyzeBigHolders(holdersWithPercentage(tokenData.solres.topHolders, tokenData.solres.supply, tokenData.solres.decimals))[0] === 0 ? 'Top 10 do not hold a significant percentage of tokens' : 'Top 10 hold a significant percentage of tokens',
    liquidityPool: totalLiquidity(tokenData) < 5000 ? totalLiquidity(tokenData) < 1 ? 'Empty liquidity pool'  : 'Low liquidity pool' :'Liquidity pool is sufficient',
    significantHolder: analyzeBigHolders(holdersWithPercentage(tokenData.solres.topHolders, tokenData.solres.supply, tokenData.solres.decimals))[1] === 0 ? 'No significant whales' : 'Significant whale could have large price impact',

    }).map(([key, value]) => ({
      key,
      value,
      isWarning: ['Avg. Liquidity ratio may be insufficient for amount of trades', 'Low liquidity pool', 'Significant whale could have large price impact'].includes(value),
      isDanger: ['Empty liquidity pool','Owner holds a significant percentage of tokens', 'Top 10 hold a significant percentage of tokens'].includes(value),
    })).sort((a, b) => {
      if (a.isDanger && !b.isDanger) return -1;
      if (!a.isDanger && b.isDanger) return 1;
      if (a.isWarning && !b.isWarning) return -1;
      if (!a.isWarning && b.isWarning) return 1;
      return 0;
    })
  : [];

  const solanaSecurityData = tokenData.solres
  ? Object.entries({
      updatable: tokenData.solres.updateAuthority !== '0' ? 'Token functionality can be changed' : "Token functionality can't be changed",
      freezable: tokenData.solres.freezeAuthority !== '0' ? 'Token is freezable' : 'Token is likely not freezable',
      mutable: tokenData.solres.mutable !== '0' ? 'Token metadata is editable' : 'Token metadata is not editable',
      mintable: tokenData.solres.mintAuthority !== '0' ? 'Token is mintable' : 'Token is likely not mintable',
      permanent_delegate: tokenData.solres.extensions? tokenData.solres.extensions.find(item => item.extension === 'permanentDelegate')? 'Permanent delegate (tokens can be burned/transferred from your account)' : 'No permanent delegate' : 'No permanent delegate',
      non_transferable: tokenData.solres.extensions? tokenData.solres.extensions.find(item => item.extension === 'nonTransferable')? 'Token is not transferable' : 'Token is transferable' : 'Token is transferable',
      immutable_owner: tokenData.solres.extensions? tokenData.solres.extensions.find(item => item.extension === 'immutableOwner')? 'Ownership not editable' : 'Ownership may be editable' : 'Ownership may be editable',
      transfer_fee: tokenData.solres.extensions? tokenData.solres.extensions.find(item => item.extension === 'transferFeeConfig')? 'Transfer fees for buying and selling' : 'No transfer fees' : 'No transfer fees',
      reallocate: tokenData.solres.extensions? tokenData.solres.extensions.find(item => item.extension === 'reallocate')? 'New extensions possible' : "New extensions not possible" : "New extensions not possible",
      owner_scammer: tokenData.solres.owner_scammer !== undefined ? (tokenData.solres.owner_scammer === 0 ? 'Owner has no previous scam' : 'Owner has previous scam') : undefined,
      heavily_shilled: tokenData.solres.heavily_shilled !== undefined ? (tokenData.solres.heavily_shilled === 0 ? 'Token has no suspicious shilling' : 'Token has suspicious shilling') : undefined,

    })
    .filter(([key, value]) => value !== undefined && value !== null && value !== '')
    .map(([key, value]) => ({
      key,
      value,
      isRisky: ['Token is freezable','Token metadata is editable','Transfer fees for buying and selling', 'Ownership may be editable', 'New extensions possible', ].includes(value!),
      isWarning: ['Token is mintable', 'Token has suspicious shilling', 'Permanent delegate (tokens can be burned/transferred from your account)', 'Token is not transferable', 'Token functionality can be changed', 'Owner has previous scam'].includes(value!),
    }))
  : [];

      const nonSolanaSecurityData = tokenData.result ? Object.entries({

        is_honeypot: tokenData.result.honeypot !== undefined ? (tokenData.result.honeypot === 0 ? 'Low Risk of Honeypot' : 'High Risk of Honeypot') : undefined,
        is_mintable: tokenData.result.mintable !== undefined ? (tokenData.result.mintable === 0 ? 'Not Mintable' : 'Mintable') : undefined,
        is_anti_whale: tokenData.result.anti_whale !== undefined ? (tokenData.result.anti_whale === 0 ? 'No Anti-Whale Mechanism' : 'Anti-Whale Mechanism') : undefined,
        is_blacklisted: tokenData.result.blacklist !== undefined ? (tokenData.result.blacklist === 0 ? 'No Blacklist' : 'Blacklist') : undefined,
        is_proxy: tokenData.result.is_proxy !== undefined ? (tokenData.result.is_proxy === 0 ? 'Not a Proxy Contract' : 'Proxy Contract') : undefined,
        verified_contract: tokenData.result.is_verified !== undefined ? (tokenData.result.is_verified === 0 ? 'Contract Not Verified' : 'Verified Contract') : undefined,
        buy_tax: tokenData.result.buy_tax !== undefined ? (tokenData.result.buy_tax === 0 ? 'No Buy Tax' : `Buy Tax`) : undefined,
        sell_tax: tokenData.result.sell_tax !== undefined ? (tokenData.result.sell_tax === 0 ? 'No Sell Tax' : `Sell Tax`) : undefined,
        is_whitelisted: tokenData.result.whitelist !== undefined ? (tokenData.result.whitelist === 0 ? 'No Whitelist' : 'Whitelist') : undefined,
        owner_change_balance: tokenData.result.owner_change_balance !== undefined ? (tokenData.result.owner_change_balance === 0 ? 'Balances Not Modifiable' : 'Balances Modifiable') : undefined,
        self_destruct: tokenData.result.self_destruct !== undefined ? (tokenData.result.self_destruct === 0 ? 'No Self Destruct' : 'Self Destruct') : undefined,
        transfer_pausable: tokenData.result.transfers_pausable !== undefined ? (tokenData.result.transfers_pausable === 0 ? 'Transfers Not Pausable' : 'Transfers Pausable') : undefined,
        cannot_sell_all: tokenData.result.cannot_sell_all !== undefined ? (tokenData.result.cannot_sell_all === 0 ? 'No Sell Limit' : 'Sell Limit') : undefined,
        trading_cooldown: tokenData.result.trading_cooldown !== undefined ? (tokenData.result.trading_cooldown === 0 ? 'No Trade Cooldown' : 'Trade Cooldown') : undefined,
        ownership_not_renounced: tokenData.result.ownership_not_renounced !== undefined ? (tokenData.result.ownership_not_renounced === 0 ? 'Ownership Renounced' : 'Ownership not renounced') : undefined,
        owner_scammer: tokenData.result.owner_scammer !== undefined ? (tokenData.result.owner_scammer === 0 ? 'Owner has no previous scam' : 'Owner has previous scam') : undefined,
        heavily_shilled: tokenData.result.heavily_shilled !== undefined ? (tokenData.result.heavily_shilled === 0 ? 'Token has no suspicious shilling' : 'Token has suspicious shilling') : undefined,

      })
      .filter(([key, value]) => value !== undefined && value !== null && value !== '')
      .map(([key, value]) => ({
        key,
        value,
        isRisky: ['Ownership not renounced', 'Token is likely freezable', 'Mintable', 'Anti-Whale Mechanism', 'Blacklist', 'Not Open Source', 'Proxy Contract', 'Buy Tax', 'Sell Tax', 'Whitelist', 'Hidden Owner', 'Trade Cooldown','Sell Limit'].includes(value!),
        isWarning: ['High Risk of Honeypot', 'Token has suspicious shilling','Balances Modifiable', 'Owner Modifiable', 'Transfers Pausable', 'Owner has previous scam'].includes(value!),
      }))
    : [];
  
    const secscore = tokenData.result != undefined ? tokenData.result.secscore : tokenData.solres != undefined ? tokenData.solres.secscore : -1

  return (
    <Card sx={{width: {xs: '100vw', sm:'32.5vw'}, height: {xs: '61vh', sm: '52vh'}, backgroundColor: 'primary.dark', marginBottom: {xs:'-1vh', sm:'1vh'}, marginTop: {xs:'0vh', sm: '0vh'}, borderRadius: {xs: 0, sm: 2}, marginLeft: {xs:'-20vw', sm:'0'}, overflowY: {xs: 'auto', sm:'auto',  md: 'hidden'}}}>
      <CardContent>
        <Typography variant='h5' sx={{fontWeight:'bold', textAlign:'left', marginLeft: '1vw', marginTop: '1vh',color:'white'}}>
        Security Score: {secscore === -1 ? (
    <span style={{ color: 'red' }}>UNVERIFIED</span>
  ) : (
    <span style={{ color: getColorFromGradient(secscore), fontWeight:'bold' }}>
      {secscore}/100
    </span>
  )}        </Typography>
        <Typography variant='h5' sx={{textAlign:'left', marginLeft: '1vw', marginTop: '1vh', fontWeight:'bold', color: 'white'}}> 
        </Typography>
        
        {tokenData && tokenData.result && chain !== 'solana'? 
            <Grid display='flex'>
            <Paper elevation={3} sx={{backgroundColor:'#1C1D21', marginLeft:'1vw', maxHeight: '17.5rem', minWidth: '20vw',maxWidth: {xs: '100vw', sm:'25vw'}, overflowY: 'auto', border: '1px solid #ccc', padding: '1rem' }}>
            {tokenData.result.is_verified != 0 ? (
    <>
      {nonSolanaSecurityData
      .sort((a, b) => {
        if (a.isWarning && !b.isWarning) return -1;
        if (!a.isWarning && b.isWarning) return 1;
        if (a.isRisky && !b.isRisky) return -1;
        if (!a.isRisky && b.isRisky) return 1;
        return 0;
      })
      .map(({ key, value, isRisky, isWarning }) => (
          <Box key={key} sx={{ backgroundColor:'#1C1D21',display: 'flex', alignItems: 'center', mb: 1 }}>
            {isWarning ? (
              <GppBadIcon sx={{ fontSize: '1.5rem', color: 'red', mr: 1 }} />
            ) : isRisky ? (
              <GppBadIcon sx={{ fontSize: '1.5rem', color: 'orange', mr: 1 }} />
            ) : (
              <GppGoodIcon sx={{ fontSize: '1.5rem', color: 'green', mr: 1 }} />
            )}
            <Typography sx={{ fontSize: '.9rem', fontWeight:'bold',color:"white", textAlign:'left'}}>
              {value}
            </Typography>
          </Box>
        ))}
    </>

  ) :           
  <Box key={3} sx={{ backgroundColor:'#1C1D21',display: 'flex', alignItems: 'center', mb: 1 }}>
  <GppBadIcon sx={{ fontSize: '1.5rem', color: 'red', mr: 1 }} />
  <Typography sx={{ fontSize: '.9rem', fontWeight:'bold',color:"white", textAlign:'left'}}>
              Contract not verified
            </Typography>
  </Box>
  
  
  }
</Paper>
            </Grid> : <div></div>}

            {tokenData && tokenData.solres? 
            <Grid display='flex'>
            <Paper elevation={3} sx={{backgroundColor:'#1C1D21', marginLeft: '1vw', maxHeight: '17.5rem', minWidth: '20vw',maxWidth: {xs: '100vw', sm:'25vw'}, overflowY: 'auto', border: '1px solid #ccc', padding: '1rem' }}>
            {(
    <>
      {solanaSecurityData
      .sort((a, b) => {
        if (a.isWarning && !b.isWarning) return -1;
        if (!a.isWarning && b.isWarning) return 1;
        if (a.isRisky && !b.isRisky) return -1;
        if (!a.isRisky && b.isRisky) return 1;
        return 0;
      })
      .map(({ key, value, isRisky, isWarning }) => (
          <Box key={key} sx={{ backgroundColor:'#1C1D21',display: 'flex', alignItems: 'center', mb: 1 }}>
            {isWarning ? (
              <GppBadIcon sx={{ fontSize: '1.5rem', color: 'red', mr: 1 }} />
            ) : isRisky ? (
              <GppBadIcon sx={{ fontSize: '1.5rem', color: 'orange', mr: 1 }} />
            ) : (
              <GppGoodIcon sx={{ fontSize: '1.5rem', color: 'green', mr: 1 }} />
            )}
            <Typography sx={{ fontSize: '.9rem', fontWeight:'bold',color:"white", textAlign:'left'}}>
              {value}
            </Typography>
          </Box>
        ))}
    </>
  )}
</Paper>
            </Grid> : <div></div>}
        
        {tokenData && chain !=='solana' && tokenData.result? 
          <div></div>
        : <div></div>}
          <Typography sx={{fontSize:'.85rem',  marginTop: '.5vh',color:'white', textAlign:'left', marginLeft:'1.1vw'}}>
          Disclaimer: The security ranking is a measure of how well the token contract meets our safety criteria. It may not be totally accurate and a token with a high security score may be dangerous. This score is NOT any form of financial or investment advice and you should always do your own research.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TokenSecurity;