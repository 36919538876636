import ToolBar from "../components/toolbar";
import Box from "@mui/material/Box";
import footer from "../components/footer";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link'

const WhiteLink = styled(Link)({
    color: 'inherit',
  });
  
const WhiteTypography = styled(Typography)({
    color: '#ffffff',
  });
export default function Terms(){
    return(<div>
        {ToolBar()}
        <Box sx ={{width:'100vw', height:'100vh', backgroundColor: 'primary.main', justifyContent:'center', alignItems:'center', display:'flex', }}>
    <Card sx={{ overflowY: 'auto',width: {xs: '100vw', sm:'65vw'}, height: {xs: '40vh', sm: '90vh'}, backgroundColor: 'primary.dark', marginBottom: {xs:'1vh', sm:'55vh'}, marginTop: {xs:'80vh', sm: '51vh'}, borderRadius: {xs: 0, sm: 2}, marginLeft: {xs:'-22vw', sm:'0'} }}>

            <CardContent sx={{marginLeft:'1vw'}}>

                <WhiteTypography variant="h4" gutterBottom>
  TokenGuide Terms of Use
</WhiteTypography>
<WhiteTypography variant="subtitle1" gutterBottom>
        Last Revised: June 3, 2024
      </WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  Welcome to TokenGuide! These Terms of Use ("Terms", "Terms of Use") govern your use of our website located at https://tokenguide.io (the "Site") and any related services provided by TokenGuide (collectively, the "Services").
</WhiteTypography>

<WhiteTypography variant="body1" paragraph>
  Please read these Terms of Use carefully before accessing or using our Site and Services. By accessing or using any part of the Site or Services, you agree to be bound by these Terms of Use. If you do not agree to all the terms and conditions of this agreement, then you may not access the Site or use the Services.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  1. DESCRIPTION OF SERVICES
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  TokenGuide provides information and analysis related to various cryptocurrencies and blockchain projects. The Services include, but are not limited to, providing market data, news, research, and tools to help users make informed decisions.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  2. INTELLECTUAL PROPERTY RIGHTS
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  Unless otherwise indicated, the Site and Services, and all content and other materials on the Site or provided through the Services, including, without limitation, the TokenGuide logo and all designs, text, graphics, pictures, information, data, software, sound files, other files, and the selection and arrangement thereof (collectively, the "Materials") are the proprietary property of TokenGuide or its licensors or users and are protected by international copyright laws.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  3. USER REPRESENTATIONS
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  By using the Site or Services, you represent and warrant that: (1) you have the legal capacity and you agree to comply with these Terms of Use; (2) you are not a minor in the jurisdiction in which you reside; (3) you will not access the Site or Services through automated or non-human means, whether through a bot, script, or otherwise; (4) you will not use the Site or Services for any illegal or unauthorized purpose; and (5) your use of the Site or Services will not violate any applicable law or regulation.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  4. PROHIBITED ACTIVITIES
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  You may not access or use the Site or Services for any purpose other than that for which we make the Site and Services available. The Site and Services may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  5. THIRD-PARTY WEBSITES AND CONTENT
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  The Site and Services may contain links to third-party websites or services that are not owned or controlled by TokenGuide. TokenGuide has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that TokenGuide shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.
</WhiteTypography>
<WhiteTypography variant="h6" gutterBottom>
  6. PRIVACY POLICY
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  Please review our Privacy Policy, which is incorporated into these Terms of Use and governs our collection and use of your personal information. You can find our Privacy Policy <WhiteLink href="/privacy_statement">here</WhiteLink>.
</WhiteTypography>
<WhiteTypography variant="h6" gutterBottom>
  7. TERMINATION
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  We may terminate or suspend your access to the Site and Services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms of Use. Upon termination, your right to use the Site and Services will immediately cease.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  8. LIMITATION OF LIABILITY
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  In no event shall TokenGuide, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation lost profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Site or Services; (ii) any conduct or content of any third party on the Site or Services; (iii) any content obtained from the Site or Services; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  9. DISCLAIMER
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  Your use of the Site and Services is at your sole risk. The Site and Services are provided on an "AS IS" and "AS AVAILABLE" basis. The Site and Services are provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  10. GOVERNING LAW
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  These Terms of Use shall be governed and construed in accordance with the laws of the jurisdiction in which TokenGuide is incorporated, without regard to its conflict of law provisions.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  11. CHANGES TO TERMS OF USE
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  We reserve the right, at our sole discretion, to modify or replace these Terms of Use at any time. If a revision is material, we will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
</WhiteTypography>

<WhiteTypography variant="body1" paragraph>
  By continuing to access or use our Site or Services after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Site and Services.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  12. CONTACT US
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  If you have any questions about these Terms of Use, please contact us via our Contact Us page.
</WhiteTypography>
            </CardContent>
        </Card>
    </Box>
    {footer()}
    
</div>)
}