import React from 'react';
import { Typography, IconButton, Link, Grid } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import XIcon from '@mui/icons-material/X';
import { DiscordIcon } from '../components/footer';
import tokenData from '../types/token';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import VoteButton from '../components/votes'

interface TokenInfoProps {
  tokenData: tokenData;
  chain: string;
  address: string;
}

interface urlmap{
    [key:string]:string
  }

 function chaintoName(chain:string){
  const map: urlmap = {
    'eth': 'ethereum',
    'ftm':'fantom',
    'arbitrum':'arbitrum',
    'avax':'avalanche',
    'cro':'cronos',
    'polygon_pos':'polygon',
    'xdai': 'gnosischain'
  }
  if (Object.values(map).includes(chain)){
    return map[chain]
  }
  else {
    return chain
  }

 }
  function chainToURL(chain:string,address:string){
    const map: urlmap = {
      'eth':`https://etherscan.io/token/${address}`, 
      'bsc':`https://bscscan.com/token/${address}`,
      'ftm':`https://ftmscan.com/token/${address}`,
      'solana':`https://solscan.io/token/${address}`,
      'avax':`https://snowscan.xyz/token/${address}`,
      'arbitrum': `https://arbiscan.io/token/${address}`,
      'zksync':`https://era.zksync.network/token/${address}`,
      'base' : `https://basescan.org/token/${address}`,
      'merlin-chain' : `https://scan.merlinchain.io/token/${address}`,
      'polygon_pos': `https://polygonscan.com/token/${address}`,
      'pulsechain' : `https://otter.pulsechain.com/address/${address}`,
      'ronin': `https://app.roninchain.com/token/${address}`,
      'hedera-hashgraph': `https://hashscan.io/mainnet/token/${address}`,
      'sei-network': `https://www.seiscan.app/pacific-1/contracts/${address}`,
      'blast': `https://blastscan.io/token/${address}`,
      'manta-pacific': `https://pacific-explorer.manta.network/token/${address}`,
      'aptos': `https://aptoscan.com/coin/${address}`,
      'xdai': `https://gnosisscan.io/token/${address}`,
      'ton': `https://tonviewer.com/${address}`,
      'bvm-nakachain': `https://explorer.nakachain.xyz/token/${address}`,
      'metis': `https://explorer.metis.io/token/${address}`,
      'tlos': `https://www.teloscan.io/address/${address}`,
      'cro': `https://www.cronoscan.io/address/${address}`,
    }
    
    const url = map[chain]
    if (!url) {
      return null;
    }
    const parsedUrl = new URL(url);
    const parts = parsedUrl.hostname.split('.');
    let res = parts.length > 1 ? parts[parts.length - 2] : null;
    return(
    <Link key={address} href={url} target="_blank" rel="noopener noreferrer" color='secondary' sx ={{marginLeft:'.35vw', textDecoration:'underline'}}>
      {`${res}`}
    </Link>)
  }

const TokenInfo: React.FC<TokenInfoProps> = ({ tokenData, chain, address }) => {
    
  return (
    <Grid container spacing={2} sx={{ padding: { xs: '1vw', sm: '1vw' } }}>
  <Grid item xs={10}>
    
    <Typography variant='h4' sx={{
      fontSize: { xs: '1.5rem', sm: '2rem' },
      fontWeight: 'bold',
      textAlign: 'left',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap'
    }}>

      {tokenData.data.attributes.name}
      {` (${tokenData.data.attributes.symbol})`}
      <VoteButton tokenaddress={address} initialCount={tokenData.tokeninfo[0].votes} chain={chain}/>
    </Typography>
    <Typography variant='h5' sx={{
      fontSize: { xs: '1rem', sm: '1.5rem' },
      textAlign: 'left',
      fontWeight: 'italic',
      color: 'white'
    }}>
    {tokenData.data.attributes.twitter_handle && (
        <IconButton
          href={`https://twitter.com/${tokenData.data.attributes.twitter_handle}`}
          rel="noopener noreferrer"
          target='_blank'
          aria-label="X"
          sx={{ marginLeft: '.25vw' }}
        >
          <XIcon color='secondary' />
        </IconButton>
      )}
      {tokenData.data.attributes.telegram_handle && (
        <IconButton
          href={`https://t.me/${tokenData.data.attributes.telegram_handle}`}
          rel="noopener noreferrer"
          target='_blank'
          aria-label="Telegram"
          sx={{ marginLeft: '.25vw' }}
        >
          <TelegramIcon color='secondary' />
        </IconButton>
      )}
      {tokenData.data.attributes.discord_url && (
        <IconButton
          href={`https://discord.com/channels/${tokenData.data.attributes.discord_url}`}
          rel="noopener noreferrer"
          target='_blank'
          aria-label="Discord"
          sx={{ marginLeft: '.25vw' }}
        >
          <DiscordIcon color='secondary' />
        </IconButton>
      )}

    </Typography>
  </Grid>
  <Grid item xs={12}>
    <Typography variant='h5' sx={{
      fontSize: { xs: '1rem', sm: '1.5rem' },
      textAlign: 'left',
      fontWeight: 'italic',
      color: 'white'
    }}>
      {`${chain}:${address}`}
    </Typography>
  </Grid>
  <Grid item xs={12}>
    <Typography variant='body1' sx={{
      textAlign: 'left',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap'
    }}>
      Links: {tokenData? chainToURL(chain, address): ''}
      {tokenData.data.attributes.websites.map((site, index) => (
        <Link
          key={index}
          href={site}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ marginLeft: '1vw', color: 'white', textDecoration:'underline'}}
        >
          {site}
        </Link>
      ))}
    </Typography>
  </Grid>
  <Grid item xs={12}>
    <Typography variant='body1' sx={{
      textAlign: 'left',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap'
    }}>
      Chart:
      <Link
        key={'geckoterm'}
        href={`https://www.geckoterminal.com/${chain}/pools/${address}`}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ marginLeft: '.25vw', color: 'white', textDecoration: 'underline'}}
      >
        {'GeckoTerminal'}
      </Link>
      <Link
        key={'dexscreener'}
        href={`https://www.dexscreener.com/${chaintoName(chain)}/${address}`}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ marginLeft: '.25vw', color: 'white', textDecoration: 'underline'}}
      >
        {'DEXScreener'}
      </Link>
      
    </Typography>
    
    <Typography sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', fontSize: '1rem', color: 'white' }}>
          KYC
          <IconButton target="_blank" rel="noopener noreferrer" href="/contact" sx={{ marginLeft: '0.5rem' }}>
            {tokenData && tokenData.tokeninfo? tokenData.tokeninfo[0].verified_kyc ? (
              <span style={{ display: 'inline-flex', alignItems: 'center', fontSize: '1rem', color: 'primary.main' }}>
                <CheckCircleIcon sx={{ color: 'green', marginRight: '0.5rem' }} /> KYC Verified
              </span>
            ) : (
              <span style={{ display: 'inline-flex', alignItems: 'center', fontSize: '1rem', color: 'white' }}>
                <CancelIcon sx={{ color: 'orange', marginRight: '0.5rem' }} /> Verify KYC
              </span>
            )
          :
          <span style={{ display: 'inline-flex', alignItems: 'center', fontSize: '1rem', color: 'primary.main' }}>
                <CancelIcon sx={{ color: 'orange', marginRight: '0.5rem' }} /> Verify Audit
              </span>
          }
          </IconButton>
        </Typography>
        <Typography sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', fontSize: '1rem', marginTop: '-1vh', color: 'white', marginRight: {xs:'-5vw'}}}>
          Manually Audited
          <IconButton target="_blank" rel="noopener noreferrer" href="/contact" sx={{ marginLeft: '0.5rem', color: 'white' }}>
            {tokenData && tokenData.tokeninfo? tokenData.tokeninfo[0].verified_audit ? (
              <span style={{ display: 'inline-flex', alignItems: 'center', fontSize: '1rem', color: 'primary.main' }}>
                <CheckCircleIcon sx={{ color: 'green', marginRight: '0.5rem' }} /> Audit Verified
              </span>
            ) : (
              <span style={{ display: 'inline-flex', alignItems: 'center', fontSize: '1rem', color: 'primary.main' }}>
                <CancelIcon sx={{ color: 'orange', marginRight: '0.5rem' }} /> Verify Audit
              </span>
            ) : <span style={{ display: 'inline-flex', alignItems: 'center', fontSize: '1rem', color: 'primary.main' }}>
            <CancelIcon sx={{ color: 'orange', marginRight: '0.5rem' }} /> Verify Audit
          </span>}
          </IconButton>
        </Typography>
  </Grid>
</Grid>
  );
};

export default TokenInfo;