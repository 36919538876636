import ToolBar from "../components/toolbar";
import Box from "@mui/material/Box";
import footer from "../components/footer";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles';

const WhiteTypography = styled(Typography)({
    color: '#ffffff',
  });
  
  const WhiteLink = styled(Link)({
    color: 'inherit',
  });
  

export default function Privacy(){
    return(<div>
        {ToolBar()}
        <Box sx ={{width:'100vw', height:'100vh', backgroundColor: 'primary.main', justifyContent:'center', alignItems:'center', display:'flex', }}>
    <Card sx={{ overflowY: 'auto',width: {xs: '100vw', sm:'65vw'}, height: {xs: '40vh', sm: '90vh'}, backgroundColor: 'primary.dark', marginBottom: {xs:'1vh', sm:'55vh'}, marginTop: {xs:'80vh', sm: '51vh'}, borderRadius: {xs: 0, sm: 2}, marginLeft: {xs:'-22vw', sm:'0'} }}>

            <CardContent sx={{marginLeft:'1vw'}}>
                <Typography sx={{fontSize:'2rem'}} color="white">
                TokenGuide Privacy Policy
                </Typography>
      <WhiteTypography variant="subtitle1" gutterBottom>
        Last Revised: March 31, 2024
      </WhiteTypography>

      <WhiteTypography variant="body1" paragraph>
  This privacy policy for TokenGuide (referred to as the "Company", "TokenGuide", "we", "our", or "us") describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services").
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  1. TYPES OF DATA WE COLLECT
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  The types of Personal Data that we collect directly from you or from third parties depend on the circumstances of collection and the nature of the service requested or transaction undertaken. It may include (but is not limited to):
</WhiteTypography>
<ul>
  <WhiteTypography variant="body1">
    (a) Personal information that links back to an individual, e.g., name, cryptocurrency wallet address.
  </WhiteTypography>
  <WhiteTypography variant="body1">
    (b) Contact information, e.g., email address.
  </WhiteTypography>
  <WhiteTypography variant="body1">
    (c) Technical information, e.g., IP address.
  </WhiteTypography>
</ul>
<WhiteTypography variant="body1" paragraph>
  This Privacy Policy covers the information we collect about you when you use our products or services or otherwise interact with TokenGuide unless a different privacy policy is displayed. This policy also explains your choices about how we use information about you.
</WhiteTypography>

<WhiteTypography variant="body1" paragraph>
  Your choices include how you can object to certain uses of information about you and how you can access and update certain information about you. If you do not agree to the terms of this Policy, do not use the Site or any of our Services. Each time you use any Site or Services, the current version of this Privacy Policy will apply.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  2. HOW YOUR PERSONAL DATA IS COLLECTED
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  This Privacy Policy covers any Personal Data provided to us:
</WhiteTypography>
<ul>
  <WhiteTypography variant="body1">
    (a) When you engage with our products and services.
  </WhiteTypography>
  <WhiteTypography variant="body1">
    (b) When you create an account with us.
  </WhiteTypography>
  <WhiteTypography variant="body1">
    (c) Under any other contractual agreement or arrangement.
  </WhiteTypography>
</ul>
<WhiteTypography variant="body1" paragraph>
  Some of the other ways we may collect Personal Data include (but are not limited to):
</WhiteTypography>
<ul>
  <WhiteTypography variant="body1">
    (a) Communications that you send via telephone and email.
  </WhiteTypography>
  <WhiteTypography variant="body1">
    (b) When you visit our website.
  </WhiteTypography>
  <WhiteTypography variant="body1">
    (c) When we collect information about you from third parties and other channels.
  </WhiteTypography>
</ul>

<WhiteTypography variant="h6" gutterBottom>
  3. HOW DO WE COLLECT YOUR PERSONAL DATA ON OUR WEBSITE?
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  We collect your Personal Data on our website in the following ways:
</WhiteTypography>
<ul>
  <WhiteTypography variant="body1">
    (a) IP address: We use your IP address to serve you our website.
  </WhiteTypography>
  <WhiteTypography variant="body1">
    (b) Cookies: We use cookies on some of our pages to record session information.
  </WhiteTypography>
  <WhiteTypography variant="body1">
    (c) User feedback form: Our feedback form requires you to provide contact information (e.g., your name and email address) so that we can respond to your comments. We use your contact information to send you information about our company and to contact you when necessary.
  </WhiteTypography>
  <WhiteTypography variant="body1">
    (d) General site tracking: We use third-party service providers to assist us in better understanding the usage of our site. These service providers place cookies in your browser and receive information that we select, such as how visitors navigate around our site, what pages are browsed, and general transaction information. The service providers analyze this information and provide us with aggregate reports.
  </WhiteTypography>
</ul>

<WhiteTypography variant="h6" gutterBottom>
  4. HOW YOUR PERSONAL DATA IS USED
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  We may use your Personal Data for the following purposes:
</WhiteTypography>
<ul>
  <WhiteTypography variant="body1">
    (a) To enable us to provide and perform our services to you.
  </WhiteTypography>

  <WhiteTypography variant="body1">
    (b) To investigate and respond to claims and inquiries from you.
  </WhiteTypography>
  <WhiteTypography variant="body1">
    (c) For business development purposes, such as statistical and marketing analysis, systems testing, maintenance and development, customer surveys, or to help us in any future dealings with you, for example, by identifying your requirements and preferences.
  </WhiteTypography>
  <WhiteTypography variant="body1">
    (d) To comply with any legal or regulatory requirements.
  </WhiteTypography>
  <WhiteTypography variant="body1">
    (e) For all other purposes ancillary to any of the purposes stated above ("Core Purposes").
  </WhiteTypography>
  <WhiteTypography variant="body1">
    (f) To communicate offers, products, services, and information on products and activities.
  </WhiteTypography>
  <WhiteTypography variant="body1">
    (g) For marketing and communications with you in relation to products and services offered by us, our service partners, and our appointed agents.
  </WhiteTypography>
  <WhiteTypography variant="body1">
    (h) For all other purposes ancillary to any of the purposes stated above ("Ancillary Purposes").
  </WhiteTypography>
</ul>
<WhiteTypography variant="body1" paragraph>
  (collectively, "Purposes")
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  5. ACCESSING, CORRECTING, AND UPDATING YOUR PERSONAL DATA
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  You may request to obtain information about your Personal Data and also update or make amendments to your Personal Data as follows: (a) For online registered customers, you may send a request to administrator@tokenguide.io.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  6. WITHDRAWING CONSENT
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  Please note that it is obligatory for the Company to process your Personal Data for the Core Purposes stated above, without which some services or features provided by TokenGuide may be affected.
</WhiteTypography>

<WhiteTypography variant="body1" paragraph>
  You may stop receiving promotional activities by:
</WhiteTypography>
<ul>
  <WhiteTypography variant="body1">
    (a) Unsubscribing from the mailing list.
  </WhiteTypography>
  <WhiteTypography variant="body1">
    (b) Sending a request via the Contact Us form.
  </WhiteTypography>
</ul>

<WhiteTypography variant="h6" gutterBottom>
  7. TO WHOM DO WE DISCLOSE YOUR PERSONAL DATA?
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  We will not trade or sell your Personal Data to third parties. Your Personal Data shall only be disclosed or transferred to the following third parties appointed or authorized by the Company for the fulfillment of the Purposes: (a) data warehouses; (b) IT service providers; (c) data analytics and/or marketing agencies; (d) legal bodies as permitted or required by law, such as in compliance with a warrant or subpoena issued by a court of competent jurisdiction; (e) regulatory authorities applicable to you; and/or (f) safety and security personnel.
</WhiteTypography>

<WhiteTypography variant="body1" paragraph>
  In addition to the above, your Personal Data may also be disclosed or transferred to any of the Company's actual and potential assignees, transferees, or acquirers (including our affiliates and subsidiaries), our business, assets, or group companies, or in connection with any corporate restructuring or exercise, including our restructuring to transfer the business, assets, and/or liabilities.
</WhiteTypography>

<WhiteTypography variant="body1" paragraph>
  We shall take practical steps to ensure that their employees, officers, agents, consultants, contractors, and such other third parties mentioned above who are involved in the collection, use, and disclosure of your Personal Data will observe and adhere to the terms of this Privacy Statement.
</WhiteTypography>

<WhiteTypography variant="body1" paragraph>
  We are subject to various legal and regulatory obligations imposed by the laws and supervisory authorities of various jurisdictions, e.g., anti-money laundering laws, anti-terrorism financing, financial services laws, corporation laws, and privacy laws. These obligations may require us to process certain data for payment processing, compliance with court orders, or other purposes not disclosed herein.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  8. LINKS TO THIRD-PARTY WEBSITES
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  We may link this website and/or our applications to other companies' or organizations' websites (collectively, "Third-Party Sites"). This Privacy Notice does not apply to such Third-Party Sites, as those sites are outside our control. If you access Third-Party Sites using the links provided, the operators of these sites may collect your Personal Information.
</WhiteTypography>

<WhiteTypography variant="body1" paragraph>
  Please ensure that you are satisfied with the privacy statements of these Third-Party Sites before you submit any Personal Information. We try, as far as we can, to ensure that all third-party linked sites have equivalent measures for the protection of your Personal Information, but we cannot be held responsible legally or otherwise for the activities, privacy policies, or levels of privacy compliance of these Third-Party Sites.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  9. ADDITIONAL INFORMATION
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  Please note that this Privacy Statement may be amended at any time in accordance with applicable laws and regulations, and such variations may be applicable to you.
</WhiteTypography>

<WhiteTypography variant="body1" paragraph>
  For further inquiries or complaints in relation to our handling of your Personal Data or our Privacy Policy, or if you wish to access, update, or amend your Personal Data as mentioned above, please contact us via our form on the Contact Us page or contact us directly at administrator@tokenguide.io.
</WhiteTypography>
            </CardContent>
        </Card>
    </Box>
    {footer()}
    
</div>)
}