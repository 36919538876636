import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { ReactComponent as TokenGuideLogo} from "../assets/logo.svg";
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { ConnectButton } from './walletconnect'
import { useEffect, useState, useContext } from 'react';
import { useAccount } from 'wagmi';
import { useWeb3ModalState } from '@web3modal/wagmi/react'
import { useWeb3ModalEvents } from '@web3modal/wagmi/react'
import { UserContext } from '../context';
import { Alert } from '@mui/material';

export default function ToolBar() {
  
  const navigate = useNavigate();
  const { address, isDisconnected } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState()
  const events = useWeb3ModalEvents()
  const { updateUserData } = useContext(UserContext);
  const [visible, setVisible] = useState(true)

  useEffect(() => { 
    
    if(events.data.event == "CONNECT_SUCCESS"){
      fetch('https://tokenguide.io/api/add', {
        method: 'POST',
        //credentials: "include",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          address: address,
          chain: selectedNetworkId
      }),
      })
      .then(response => response.json())
      .then(data => {
        const nonce = data.nonce;
 
      document.cookie = `nonce=${nonce}; Path=/; SameSite=Strict; Secure`;
      })
      .catch(error => {
        
      });
      if (address){

      
      fetch('https://tokenguide.io/api/getuservotes', {
        method: 'POST',
        //credentials: "include",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          address: address + '-' + selectedNetworkId,
      }),
      })
      .then(response => response.json())
      .then(data => {
        updateUserData(address, data.uservotes);
        const userVotesString = JSON.stringify(data.uservotes);
        document.cookie = `userVotes=${encodeURIComponent(userVotesString)}; SameSite=Strict; Secure; Path=/`;
      })
    }
      

    }

    if (isDisconnected){
      updateUserData('',null)
      document.cookie = "nonce=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = `userVotes=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;

    }
   
  }, [events, isDisconnected]) 
  
  return ( 
    <div>
            {visible && <Alert variant='filled' sx={{backgroundColor:"#2E9F7E", borderRadius: 0}} severity="info" onClose={() => {setVisible(false)}}>
  Try our telegram mini app for security audits in-app! <a style={{color:'white'}} rel="noopener noreferrer" target='_blank' href='https://t.me/tokenguidemini_bot'>Link to App</a>
</Alert>}
    
    <Box sx={{ flexGrow: 1 }}>
  <AppBar position="static" sx={{ bgcolor: 'primary.dark', height: '7vh', paddingTop: '.5vh' }}>
    <Toolbar variant="dense" sx={{ height: '7.5vh' }}>
      <Box
        component={TokenGuideLogo}
        onClick={() => navigate('/')}
        sx={{
          marginLeft: { xs: '2vw', sm: '5vw', md: '13.5vw' },
          height: { xs: '5rem', sm: '4rem', md: '5rem' },
          width: { xs: '10rem', sm: '8rem', md: '12rem' },
          cursor: 'pointer',
        }}
      />
      <Typography sx={{ fontSize: {xs: '.7rem', sm: '.9rem'}, fontWeight: 'bold', color:'#2E9F7E' }}>BETA</Typography>
      <Box sx={{ marginLeft: { xs: '1.5vw', sm: '50vw' } }}>{ConnectButton()}</Box>
    </Toolbar>
  </AppBar>
</Box>
</div>

  );
}