import { useEffect, useState } from 'react';
import '../styles/Main.css';
import toolBar from '../components/toolbar';
import Box from '@mui/material/Box';
import footer from '../components/footer';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import tokenData from '../types/token';
import TokenSecurity from '../components/TokenSecurity';
import HoldersAndLiquidity from '../components/HoldersAndLiquidity';
import TokenCard from '../components/TokenCard'
import CircularProgress from '@mui/material/CircularProgress';
import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react'


export default function Token() {
  const params = useParams();
  const [trustScore, setTrustScore] = useState('');
  const [tokenData, setTokenData] = useState<tokenData>();
  const [loading, setLoading] = useState(true);
  const tokenadd = params.tokenAddress ? params.tokenAddress.split('+')[0] : '';
  const chain = params.tokenAddress ? params.tokenAddress.split('+')[1] : '';
  const { address, isDisconnected } = useAccount();
  const { open, close } = useWeb3Modal()
  const [limited, setLimited] = useState(false);
  useEffect(() => {
    if (chain && tokenadd && address) {
      fetch(`https://tokenguide.io/api/token?network=${encodeURIComponent(chain)}&address=${encodeURIComponent(tokenadd)}`, {
        headers: {
          'x-user': address
        }
      })        
      .then((response) => response.json())
        .then((data: tokenData) => {
          setTokenData(data);
        })
        .then(() => setLoading(false))

    }
    else if (chain && tokenadd && !address) {
        fetch(`https://tokenguide.io/api/token?network=${encodeURIComponent(chain)}&address=${encodeURIComponent(tokenadd)}`)        
        .then((response) => {
          console.log(response)
          if (response.status == 429) {
            setLimited(true)
            open();
            return
          }
          return response.json();
        })
          .then((data: tokenData) => {
            setTokenData(data);
          })
          .then(() => setLoading(false))
  
      }
  }, [isDisconnected]);

  return (
    <div>
      {toolBar()}
      <div className="Main-body">
        <Box sx={{width:'100vw', height:'100vh', backgroundColor: 'primary.main', justifyContent:'center', alignItems:'center', display:'flex'}}>
          <Box sx={{width:'65vw', marginBottom: {xs:'5vh',sm:'60vh'}, marginLeft: {xs:'5vw',sm:'0'}}}>
          {tokenData?
          <TokenCard tokenData={tokenData} chain={chain} address={tokenadd} />
            :     
            loading ? (<Box><CircularProgress sx={{height:'5rem', width:'5rem', color:'#2E9F7E'}} /></Box>) 
              
              :
            (
              <Card sx={{width: {xs: '100vw', sm:'65vw'}, height: {xs: '40vh', sm: '35vh'}, backgroundColor: 'primary.dark', marginBottom: '1vh', marginTop: {xs:'-47.5vh', sm: '0vh'}, borderRadius: {xs: 0, sm: 2}, marginLeft: {xs:'-22vw', sm:'0'} }}>
                <CardContent>
                  <Typography variant='h4' sx={{fontSize: '1rem', fontWeight: 'bold', textAlign: 'left', marginLeft: '1vw', marginTop: '1vh', color: 'white' }}>
                    {limited? 'Please connect your wallet to continue auditing tokens!' : 'No results found...'}
                  </Typography>
                </CardContent>
              </Card>)
        }
            <Grid sx={{display: {xs: 'flex-column', sm: 'flex'}}}>
              {tokenData?
              <><TokenSecurity tokenData={tokenData} chain={chain} address={tokenadd} trustScore={trustScore} />
              <HoldersAndLiquidity tokenData={tokenData} chain={chain} address={tokenadd} /></>

              : <div></div>
              }
            </Grid>
          </Box>
        </Box>
      </div>

      {footer()}

      
    </div>
  );
}