import React, { useState, useEffect,  } from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useWeb3ModalState } from '@web3modal/wagmi/react'
import { useSignMessage } from 'wagmi'

interface VoteButtonProps {
  initialCount: number;
  tokenaddress: string;
  chain: string;
}

const VoteCount = styled(Typography)({
  display: 'inline-block',
});

const VoteButtonContainer = styled(Box)({
  display: 'flex',
  alignItems:'center',
  backgroundColor: '#1C1D21',
  borderRadius: 30,
  marginLeft:'1vw'
});

function getNonce(name: string): string {
  const nonce = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
  return nonce ? nonce.pop() || '' : '';
}

function getUserVotes() {
  const cookieName = `userVotes`;
  const cookieValue = document.cookie.match(`(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`);
  const userVotesString = cookieValue ? decodeURIComponent(cookieValue.pop()!) : null;
  return userVotesString ? JSON.parse(userVotesString) : {};
}

const removeVote = (address: string, tokenAddress: string, chain: string) => {
  const cookieName = `userVotes`;
  const cookieValue = document.cookie.match(`(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`);
  const userVotesString = cookieValue ? decodeURIComponent(cookieValue.pop()!) : null;
  const userVotes = userVotesString ? JSON.parse(userVotesString) : {};

  const key = `${tokenAddress},${chain}`;
  if (userVotes[key]) {
    delete userVotes[key];

    if (Object.keys(userVotes).length === 0) {
      // If the userVotes object is empty, remove the entire cookie
      document.cookie = `userVotes=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    } else {
      // If the userVotes object is not empty, update the cookie with the modified object
      const updatedUserVotesString = JSON.stringify(userVotes);
      document.cookie = `userVotes=${encodeURIComponent(updatedUserVotesString)}; SameSite=Strict; Secure; Path=/`;
    }
  }
};

const addVote = (address: string, tokenAddress: string, chain: string, voteType: number) => {
  const cookieName = `userVotes`;
  const cookieValue = document.cookie.match(`(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`);
  const userVotesString = cookieValue ? decodeURIComponent(cookieValue.pop()!) : null;
  const userVotes = userVotesString ? JSON.parse(userVotesString) : {};

  const key = `${tokenAddress},${chain}`;
  userVotes[key] = voteType;

  const updatedUserVotesString = JSON.stringify(userVotes);
  document.cookie = `userVotes=${encodeURIComponent(updatedUserVotesString)}; SameSite=Strict; Secure; Path=/`;
};
const VoteButton: React.FC<VoteButtonProps> = ({ initialCount = 0, tokenaddress, chain }) => {
  const [finalnonce, setNonce] = useState('');
  const [count, setCount] = useState(initialCount);
  const [voted, setVoted] = useState<1 | 0 | null>(null);
  const { address, isConnecting, isDisconnected } = useAccount();
  const { open, close } = useWeb3Modal()
  const { selectedNetworkId } = useWeb3ModalState()
  const nonce = getNonce('nonce');
  const { data, error, isError, isSuccess, signMessage } = useSignMessage()
  const [uservotes, setUserVotes] = useState(getUserVotes());

  useEffect(() => {
    if (address && !isDisconnected) {
      if (uservotes){
        uservotes[tokenaddress + ',' + chain] === 0 ? setVoted(0) : uservotes[tokenaddress + ',' + chain] === 1 ? setVoted(1) : setVoted(null)
      }
    }
  
    else{
      setVoted(null)
    }
  }, [address, isDisconnected])


  const handleVote = async (voteType: 1 | 0) => {

    if (isDisconnected) {
      open();
      return
    }
  
    else if (address && !isDisconnected) {
      try {

        const message = 'Sign to vote. Nonce: ' + nonce + '';
        const options = {
          onSuccess: (data:any) => {
            fetch('https://tokenguide.io/api/vote', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                walletAddress: address + '-' + selectedNetworkId,
                tokenAddress: tokenaddress,
                chain: chain,
                vote: voteType,
                signature: data,
              }),
            }).then(response => response.json())
            .then(data => {
              const nonce = data.nonce;
              
            document.cookie = `nonce=${nonce}; Path=/; SameSite=Strict; Secure`;
            });
            if (voted === 0 && voteType === 0){
              setVoted(null)
              setCount(count + 1)
              removeVote(address, tokenaddress, chain);
            }
            else if (voted === 1 && voteType === 1){
              setVoted(null)
              setCount(count - 1)
              removeVote(address, tokenaddress, chain);
            }

            else{
            if (voted !== null){
              if (voted === 1 && voteType === 0){
                setVoted(0)
                setCount(count - 2)
                addVote(address, tokenaddress, chain, voteType);
              }

              else if (voted === 0 && voteType === 1){
                setVoted(1)
                setCount(count + 2)
                addVote(address, tokenaddress, chain, voteType);
              }
            }

            if (voted === null){
              setVoted(voteType);
            voteType === 0 ? setCount(count - 1) : setCount(count + 1);
            addVote(address, tokenaddress, chain, voteType);

            }
            
            }
            
          },
        };
        await signMessage({ message}, options);
          
        // Handle the response...
      } catch (error) {
        console.error('Error recording vote:', error);
      }
    }
  };

//#2E9F7E
  return (
    <VoteButtonContainer>
      <IconButton onClick={() => handleVote(1)}>
        {voted === 1? <ArrowUpward sx={{ color: '#2E9F7E','&:hover': {color: '#2E9F7E'} }} /> : <ArrowUpward sx={{ color: 'white','&:hover': {color: '#2E9F7E'} }} />}
      </IconButton>
      <VoteCount sx={{fontSize:'1rem', fontWeight:'bold'}}>{count < 0 ? <span style={{color:'red'}}>{count}</span> : count > 0 ? <span style={{color:'#2E9F7E'}}>{count}</span> : count}</VoteCount>
      <IconButton onClick={() => handleVote(0)}>
        {voted === 0? <ArrowDownward sx={{ color: 'red','&:hover': {color: 'red'} }} /> : <ArrowDownward sx={{ color: 'white','&:hover': {color: 'red'} }} />}
      </IconButton>
    </VoteButtonContainer>
  );
};

export default VoteButton;