import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Card, CardContent, MenuItem, Grid, Checkbox, FormControlLabel } from '@mui/material';

interface AdvertFormProps {
  onSubmit: (formData: any) => void;
}

const AdvertForm: React.FC<AdvertFormProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    contactName: '',
    companyEmail: '',
    websiteUrl: '',
    companyName: '',
    budget: '',
    campaignPeriod: '',
    advertText: '',
    comments: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
    setFormData({
      contactName: '',
      companyEmail: '',
      websiteUrl: '',
      companyName: '',
      budget: '',
      campaignPeriod: '',
      advertText: '',
      comments: '',
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{ backgroundColor: 'primary.main' }}
    >
      <Card sx={{ minWidth: '10vw', maxWidth: '40vw', minHeight:'10vh', maxHeight:'90vh', marginBottom:'10vh'}}>
        <CardContent>
          <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '1rem' }} gutterBottom>
            Advertising Inquiry
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Contact Name"
                  fullWidth
                  margin="normal"
                  variant="filled"
                  name="contactName"
                  value={formData.contactName}
                  onChange={handleChange}
                  required
                  sx={{
                    backgroundColor: 'white',
                    marginBottom: '1rem',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Company Email Address"
                  type="email"
                  fullWidth
                  margin="normal"
                  variant="filled"
                  name="companyEmail"
                  value={formData.companyEmail}
                  onChange={handleChange}
                  required
                  sx={{
                    backgroundColor: 'white',
                    marginBottom: '1rem',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Official Website URL"
                  fullWidth
                  margin="normal"
                  variant="filled"
                  name="websiteUrl"
                  value={formData.websiteUrl}
                  onChange={handleChange}
                  required
                  sx={{
                    backgroundColor: 'white',
                    marginBottom: '1rem',
                  }}

                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Company Name / Project Name"
                  fullWidth
                  margin="normal"
                  variant="filled"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  sx={{
                    backgroundColor: 'white',
                    marginBottom: '1rem',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Advertising Budget"
                  select
                  fullWidth
                  margin="normal"
                  variant="filled"
                  name="budget"
                  value={formData.budget}
                  onChange={handleChange}
                  required
                  sx={{
                    backgroundColor: 'white',
                    marginBottom: '1rem',
                  }}
                >
                  <MenuItem value="Less than $5,000">Less than $5,000</MenuItem>
                  <MenuItem value="$5,000 - $10,000">$5,000 - $10,000</MenuItem>
                  <MenuItem value="$10,000 - $25,000">$10,000 - $25,000</MenuItem>
                  <MenuItem value="Over $25,000">$25,000+</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Campaign Period"
                  fullWidth
                  margin="normal"
                  variant="filled"
                  name="campaignPeriod"
                  value={formData.campaignPeriod}
                  onChange={handleChange}
                  sx={{
                    backgroundColor: 'white',
                    marginBottom: '1rem',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Advertisement Display Text"
                  fullWidth
                  margin="normal"
                  variant="filled"
                  name="advertText"
                  value={formData.advertText}
                  onChange={handleChange}
                  required
                  sx={{
                    backgroundColor: 'white',
                    marginBottom: '1rem',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Comments"
                  multiline
                  rows={2}
                  fullWidth
                  margin="normal"
                  variant="filled"
                  name="comments"
                  value={formData.comments}
                  onChange={handleChange}
                  sx={{
                    backgroundColor: 'white',
                    marginBottom: '1rem',
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              color="info"
              sx={{
                width: '100%',
                borderRadius: 0,
              }}
            >
              Submit Inquiry
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AdvertForm;