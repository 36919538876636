import '../styles/Main.css';
import GetStarted from '../components/getStarted';
import toolBar from '../components/toolbar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography';
import footer from '../components/footer';
import { Link } from '@mui/material';
import AlertSignup from '../components/alertSignup';

export default function Main() {
  const handleAlertSignup = (email: string) => {
    fetch('https://tokenguide.io/subscribe', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: email
          }),
          });
  };
  return (
    <div className="Main">
      <header className="Main-header">{toolBar()}</header>
        <Box
          sx={{
            width: '100%',
            height: { xs: '40vh', sm: '30vh' },
            backgroundColor: 'primary.main',
            paddingTop: { xs: '4vh', sm: '5vh' },
            marginBottom: { xs: '0vh', sm: '-5vh' },
          }}
        >
          <Typography
            color="secondary"
            component="h1"
            variant="h3"
            sx={{
              marginLeft: { xs: '5vw', sm: '15vw' },
              fontSize: { xs: '2rem', sm: '3rem' },
              textAlign: 'left',
              fontWeight: 'bold',
            }}
          >
            Building {<span style={{color:'#2E9F7E'}}>security</span>} for web3.
          </Typography>
          <Typography
            color="secondary"
            paddingTop=".5vh"
            component="h1"
            variant="h3"
            sx={{
              marginLeft: { xs: '5vw', sm: '15vw' },
              fontSize: { xs: '2rem', sm: '3rem' },
              textAlign: 'left',
              fontWeight: 'bold',
            }}
          >
            Across {<span style={{color:'#2E9F7E'}}>all</span>} blockchains.
          </Typography>
          {GetStarted()}
         {/*  <Typography
            color="#2E9F7E"
            variant="h3"
            sx={{
              marginLeft: { xs: '5vw', sm: '15vw' },
              fontSize: { xs: '.85rem', sm: '.95rem' },
              paddingTop:{xs:".5vh", sm:'1vh'},
              textAlign: 'left',
              fontWeight: 'bold',
            }}
          >
            Sponsored: <span style={{fontWeight: 'normal'}}><Link rel="noopener noreferrer" href="/advertise" sx={{color:'white'}}>YOUR AD HERE</Link></span>
            </Typography> */}
        </Box>
        <Grid
          container
          sx={{
            width: '100%',
            paddingTop: { xs: '0vh', sm: '8vh' },
            marginTop: { xs: '-15vh', sm: '0vh' },
            paddingBottom: { xs: '4vh', sm: '25vh' },
            backgroundColor: 'primary.main',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Grid item xs={12} sm={4}>
            <Typography
              color="white"
              component="h1"
              variant="h5"
              sx={{
                fontSize: { xs: '1rem', sm: '1.5rem' },
                marginBottom: { xs: '1rem', sm: 0 },
              }}
            >
              Automated security audits.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              color="white"
              variant="h5"
              component="h1"
              sx={{
                fontSize: { xs: '1rem', sm: '1.5rem' },
                marginBottom: { xs: '1rem', sm: 0 },
              }}
            >
              Free to use on all blockchains.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              color="white"
              variant="h5"
              component="h1"
              sx={{ fontSize: { xs: '1rem', sm: '1.5rem' } }}
            >
              Community voted tokens.
            </Typography>
          </Grid>
        </Grid>
        <AlertSignup onSubmit={handleAlertSignup}></AlertSignup>
        
      {footer()}
    </div>
  );
}


