import ToolBar from "../components/toolbar";
import Box from "@mui/material/Box";
import footer from "../components/footer";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link'

const WhiteLink = styled(Link)({
    color: 'inherit',
  });
  
const WhiteTypography = styled(Typography)({
    color: '#ffffff',
  });
export default function Cookies(){
    return(<div>
        {ToolBar()}
        <Box sx ={{width:'100vw', height:'100vh', backgroundColor: 'primary.main', justifyContent:'center', alignItems:'center', display:'flex', }}>
    <Card sx={{ overflowY: 'auto',width: {xs: '100vw', sm:'65vw'}, height: {xs: '40vh', sm: '90vh'}, backgroundColor: 'primary.dark', marginBottom: {xs:'1vh', sm:'55vh'}, marginTop: {xs:'80vh', sm: '51vh'}, borderRadius: {xs: 0, sm: 2}, marginLeft: {xs:'-22vw', sm:'0'} }}>

            <CardContent sx={{marginLeft:'1vw'}}>
            <WhiteTypography variant="h4" gutterBottom>
  TokenGuide Cookies Policy
</WhiteTypography>

<WhiteTypography variant="body1" paragraph>
  This Cookies Policy explains how TokenGuide ("Company", "we", "us", or "our") uses cookies and similar technologies to recognize you when you visit our website at https://tokenguide.io (the "Site"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  1. WHAT ARE COOKIES?
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  2. WHY DO WE USE COOKIES?
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  We use cookies for several reasons. Some cookies are required for technical reasons for our Site to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies enable us to track and target the interests of our users to enhance the experience on our Site. Third parties serve cookies through our Site for analytics and other purposes.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  3. TYPES OF COOKIES WE USE
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  The specific types of cookies served through our Site and the purposes they perform are described below:
</WhiteTypography>

<WhiteTypography variant="body1" paragraph>
  (a) Essential Cookies: These cookies are strictly necessary to provide you with services available through our Site and to use some of its features, such as access to secure areas. We use a nonce policy for session management, which is an essential cookie. We also use a "user votes" cookie to store information about the votes a user has cast on our Site.
</WhiteTypography>

<WhiteTypography variant="body1" paragraph>
  (b) Analytics and Customization Cookies: These cookies collect information that is used either in aggregate form to help us understand how our Site is being used or how effective our marketing campaigns are, or to help us customize our Site for you.
</WhiteTypography>

<WhiteTypography variant="body1" paragraph>
  (c) Third-Party Cookies: We use cookies from WalletConnect, a third-party service, to facilitate user sessions and enable certain features on our Site. These cookies are not under our direct control, and you should refer to WalletConnect's privacy policy for more information.
</WhiteTypography>


<WhiteTypography variant="h6" gutterBottom>
  4. WHAT ARE YOUR CHOICES REGARDING COOKIES?
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser. Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  5. WHERE CAN YOU FIND MORE INFORMATION ABOUT COOKIES?
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  You can learn more about cookies at the following third-party websites:
</WhiteTypography>
<ul>
  <WhiteTypography variant="body1">
    All About Cookies: <WhiteLink href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org/</WhiteLink>
  </WhiteTypography>
  <WhiteTypography variant="body1">
    Network Advertising Initiative: <WhiteLink href="http://www.networkadvertising.org/">http://www.networkadvertising.org/</WhiteLink>
  </WhiteTypography>
</ul>

<WhiteTypography variant="h6" gutterBottom>
  6. CHANGES TO THIS COOKIES POLICY
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  We may update this Cookies Policy from time to time to reflect changes to the cookies we use or for other operational, legal, or regulatory reasons. Please revisit this Cookies Policy regularly to stay informed about our use of cookies and related technologies.
</WhiteTypography>

<WhiteTypography variant="h6" gutterBottom>
  7. CONTACT US
</WhiteTypography>
<WhiteTypography variant="body1" paragraph>
  If you have any questions about our use of cookies or other technologies, please contact us via our Contact Us page.
</WhiteTypography>
            </CardContent>
        </Card>
    </Box>
    {footer()}
    
</div>)
}