import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Card, CardContent, MenuItem } from '@mui/material';

interface ContactFormProps {
    onSubmit: (name: string, email: string, message: string, subject: string) => void;
  }

const ContactForm: React.FC<ContactFormProps> = ({ onSubmit }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(name, email, message,subject);
    setName('');
    setEmail('');
    setMessage('');
    setSubject('');
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{ backgroundColor: 'primary.main' }}
    >
      <Card sx={{ minWidth: '30vw', maxWidth: '30vw', marginBottom:'25vh', }}>
        <CardContent>
          <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '1rem' }} gutterBottom>
            Contact Us
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <TextField
              label="Name"
              fullWidth
              margin="normal"
              variant="filled"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              sx={{
                backgroundColor: 'white',
                marginBottom: '1rem',
              }}
            />
            <TextField
              label="Email"
              type="email"
              fullWidth
              margin="normal"
              variant="filled"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{
                backgroundColor: 'white',
                marginBottom: '1rem',
              }}
            />
             <TextField
              label="Subject"
              select
              fullWidth
              margin="normal"
              variant="filled"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              sx={{
                backgroundColor: 'white',
                marginBottom: '1rem',
              }}
            >
              <MenuItem value="option1">Report a Scam</MenuItem>
              <MenuItem value="option2">General</MenuItem>
              <MenuItem value="option3">Verify KYC</MenuItem>
              <MenuItem value="option4">Verify Audit</MenuItem>
            </TextField>
            <TextField
              label="Message"
              multiline
              rows={4}
              fullWidth
              margin="normal"
              variant="filled"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              sx={{
                backgroundColor: 'white',
                marginBottom: '1rem',
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="info"
              sx={{
                width: '100%',
                borderRadius: 0,
              }}
            >
              Send
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ContactForm;