import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { ReactComponent as TokenGuideLogo} from "../assets/logo.svg";
import XIcon from '@mui/icons-material/X';
import IconButton from '@mui/material/IconButton';
import TelegramIcon from '@mui/icons-material/Telegram';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import CookieConsent from 'react-cookie-consent';

export const DiscordIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z" />
  </SvgIcon>
);
export default function BottomNav() : React.ReactElement {

  return (
    <Box sx={{ width: '100%', backgroundColor: 'primary.dark', py: { xs: 4, sm: 6 }}}>
        <CookieConsent
          buttonStyle={{ backgroundColor:'#2E9F7E', color: "white", fontSize: "13px" }}
          enableDeclineButton
        >
        This website uses necessary cookies to provide functionality for the website. Users can opt to decline optional cookies.
        </CookieConsent>

  <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ paddingRight: { xs: '0', sm: 0 }}}>
    <Grid item xs={12} display="flex" justifyContent="center">
    <Box
        component={TokenGuideLogo}
        sx={{
          marginLeft: { xs: '2vw', sm: '0vw', md: '0vw' },
          height: { xs: '5rem', sm: '4rem', md: '6rem' },
          width: { xs: '10rem', sm: '8rem', md: '12rem' },
        }}
      />
    </Grid>
    <Grid item xs={12} display="flex" justifyContent="center">
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center">
        <Link rel="noopener noreferrer" href="/advertise" color='secondary' variant='body1' sx={{ mb: { xs: 1, sm: 0 }, mr: { xs: 0, sm: 2 } }}>
          Advertise
        </Link>
        <Link rel="noopener noreferrer" href="/contact" color='secondary' variant='body1' sx={{ mb: { xs: 1, sm: 0 }, mr: { xs: 0, sm: 2 } }}>
          Contact Us
        </Link>
        <Link rel="noopener noreferrer" href="/terms_of_use" color='secondary' variant='body1' sx={{ mb: { xs: 1, sm: 0 }, mr: { xs: 0, sm: 2 } }}>
          Terms of Use
        </Link>
        <Link rel="noopener noreferrer" href="/privacy_statement" color='secondary' variant='body1' sx={{ mb: { xs: 1, sm: 0 }, mr: { xs: 0, sm: 2 } }}>
          Privacy Policy
        </Link>
        <Link rel="noopener noreferrer" href="/cookies" color='secondary' variant='body1'>
          Cookies Policy
        </Link>
      </Box>
    </Grid>
  </Grid>
  <Divider variant='middle' sx={{ my: 2 }} />
  <Box display='flex' flexDirection={{ xs: 'column', sm: 'row' }} justifyContent='center' alignItems='center' sx={{paddingRight: { xs: '0', sm: 0 }}}>
    <Typography color='secondary' variant='body2' sx={{ mb: { xs: 1, sm: 0 }, mr: { xs: 0, sm: 2 } }}>
      © Copyright TokenGuide 2024
    </Typography>
    <Box display='flex' justifyContent='center'>
      <IconButton rel="noopener noreferrer" target='_blank' href="https://twitter.com/tokenguideio" aria-label="TokenGuide Twitter">
        <XIcon fontSize='small' color='secondary' />
      </IconButton>
      <IconButton href="https://t.me/tokenguidesecurity" rel="noopener noreferrer" target='_blank' aria-label="TokenGuide Telegram">
        <TelegramIcon fontSize='small' color='secondary' />
      </IconButton>
      <IconButton href="https://discord.com/invite/S6MjBPhhU6" rel="noopener noreferrer" target='_blank' aria-label="TokenGuide Discord">
        <DiscordIcon fontSize='small' color='secondary' />
      </IconButton>
    </Box>
    <Typography color='secondary' variant='body2' sx={{ mb: { xs: 1, sm: 0 }, mr: { xs: 0, sm: 2 }, ml: {xs: 0, sm:2} }}>
    Powered by Etherscan.io APIs
    </Typography>
  </Box>
  
</Box>
  );
}