// TokenCard.js
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

import TokenInfo from './TokenInfo';
import tokenData from '../types/token'
interface TokenCardProps {
    tokenData: tokenData;
    chain: string;
    address: string;
  }
  const TokenCard: React.FC<TokenCardProps> = ({ tokenData, chain, address }) => {
    return (
    <Card sx={{width: {xs: '100vw', sm:'65vw'}, overflowY:{xs:'auto', sm:'auto', md:'hidden'}, height: {xs: '40vh', sm: '35vh'}, backgroundColor: 'primary.dark', marginBottom: '1vh', marginTop: {xs:'80vh', sm: '51vh'}, borderRadius: {xs: 0, sm: 2}, marginLeft: {xs:'-22vw', sm:'0'} }}>
      <CardContent>
        {(!tokenData || 'status' in tokenData || (tokenData && ('errors' in tokenData))) ? (
          <Typography variant='h4' sx={{fontSize: '1rem', fontWeight: 'bold', textAlign: 'left', marginLeft: '1vw', marginTop: '1vh', color: 'white' }}>
            {'No results found...'}
          </Typography>
        ) : (
          <TokenInfo tokenData={tokenData} chain={chain} address={address} />
        )}
        
      </CardContent>
    </Card>
  );
};

export default TokenCard;