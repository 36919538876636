import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { WagmiProvider } from 'wagmi'
import { mainnet, bsc, polygon, arbitrum, scroll, avalanche, cronos  } from 'wagmi/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactNode } from 'react'

interface Web3ModalProviderProps {
  children: ReactNode;
}
// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '3576015eb7f1c263c9b0073f3faab72b'

// 2. Create wagmiConfig
const metadata = {
  name: 'TokenGuide',
  description: 'TokenGuide',
  url: 'https://tokenguide.io', // origin must match your domain & subdomain
  icons: ['']
}

const chains = [mainnet, bsc, polygon, arbitrum, scroll, avalanche, cronos] as const
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  
})

// 3. Create modal
createWeb3Modal({
  themeVariables: {
    "--w3m-accent": "#2E9F7E",
      },
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, 
  enableOnramp: true,
  

})

export function Web3ModalProvider({ children } : Web3ModalProviderProps) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}

export function ConnectButton() {
  return <w3m-button />
}