import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './styles/index.css';
import Main from './routes/Main';
import Token from './routes/Token';
import Terms from './routes/Terms';
import Cookies from './routes/Cookies';
import Privacy from './routes/Privacy';
import { ThemeProvider, createTheme } from '@mui/material';
import ErrorPage from "./routes/ErrorPage";
import Contact from "./routes/Contactus"
import Advertise from './routes/Advertise'
import { Web3ModalProvider } from "./components/walletconnect";
import ErrorBoundary from './components/errorboundary';
import { UserProvider } from "./context";
const theme = createTheme({
  palette: {
    primary:{
      main: "#1C1D21",
      light: "#F0EFF4",
      dark: '#161616'
    },
    secondary: {
      main: "#FFFFFF",
    }
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main/>,
    errorElement: <ErrorPage />
  },
  {
    path: "tokens/:tokenAddress",
    element: <Token/>,
  },
  {
    path: "terms_of_use",
    element: <Terms/>,
  },
  {
    path: "cookies",
    element: <Cookies/>,
  },
  {
    path: "privacy_statement",
    element: <Privacy/>,
  },
  {
    path: "contact",
    element: <Contact/>
  },
  {
    path:'advertise',
    element: <Advertise/>
  }

  
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
    <Web3ModalProvider>
      <UserProvider>
    <RouterProvider router={router} />
    </UserProvider>
      </Web3ModalProvider>
    </ThemeProvider>
    </ErrorBoundary>
);

