import React, { createContext, useState, ReactNode } from 'react';

interface UserContextType {
  walletAddress: string;
  userVotes: any;
  updateUserData: (walletAddress: string, userVotes: any) => void;
}

export const UserContext = createContext<UserContextType>({
  walletAddress: '',
  userVotes: null,
  updateUserData: () => {},
});

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState('');
  const [userVotes, setUserVotes] = useState(null);

  const updateUserData = (newWalletAddress: string, newUserVotes: any) => {
    setWalletAddress(newWalletAddress);
    setUserVotes(newUserVotes);
  };

  return (
    <UserContext.Provider value={{ walletAddress, userVotes, updateUserData }}>
      {children}
    </UserContext.Provider>
  );
};