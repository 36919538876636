import React from "react";
import footer from "../components/footer";
import ToolBar from "../components/toolbar";
import Box from "@mui/material/Box";
import AdvertForm from '../components/advertise'

export default function Advertise(){
    const handleAdvertise = (formData: any) => {
        
          fetch('https://tokenguide.io/send', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name: formData.contactName,
              email: formData.companyEmail,
              subject: 'Advertise',
              message: formData.websiteUrl + '_' + formData.companyName + '_' + formData.budget + '_' + formData.campaignPeriod + '_' + formData.advertText + '_' + formData.comments,
          }),
          });
        }

    return(<div>
            {ToolBar()}
        <Box sx ={{width:'100vw', height:'100vh', backgroundColor: 'primary.main', justifyContent:'center', alignItems:'center', display:'flex', }}>
            <AdvertForm onSubmit={handleAdvertise}></AdvertForm>
        </Box>
        {footer()}
        
    </div>)
}