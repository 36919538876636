import ToolBar from "../components/toolbar";
import Box from "@mui/material/Box";
import footer from "../components/footer";
import ContactForm from '../components/contactform'

export default function Contact(){
    const handleContact = (name:string, email: string, subject: string, message: string) => {
        // Handle the alert signup logic here
        fetch('https://tokenguide.io/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, subject, message }),
      });
        console.log('Signed up for alerts with email:', email);
      };
      
    return(<div>
        {ToolBar()}
    <Box sx ={{width:'100vw', height:'100vh', backgroundColor: 'primary.main', justifyContent:'center', alignItems:'center', display:'flex', }}>
    <ContactForm onSubmit={handleContact}></ContactForm>
    </Box>
    {footer()}
    
</div>)
}