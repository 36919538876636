import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';

interface AlertSignupProps {
  onSubmit: (email: string) => void;
}

const AlertSignup: React.FC<AlertSignupProps> = ({ onSubmit }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(email);
    setEmail('');
  };

  return (
    <Box
      display="flex"
      flexDirection='column'
      justifyContent="center"
      minHeight="20vh"
      sx={{ backgroundColor: 'primary.main', alignItems:{xs: 'flex-start', sm: 'center'}, paddingLeft:{xs: '5vw', sm: '0'}, marginTop:{xs:'0', sm:'-1vh'}}}
    >
         <Typography sx={{fontSize: {xs: '.75rem', sm: '1.25rem'},color: 'white', fontWeight: 'bold', textAlign: 'center' }} gutterBottom>
          Get scam alerts and updates delivered to your inbox!
        </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          minWidth: '28vw',
          maxWidth: {xs: '100vw',sm:'28vw'},
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent:'center'
        }}
      >
       
        <TextField
          label="Email"
          type="email"
          fullWidth
          margin="normal"
          variant="filled"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          sx={{
            backgroundColor: 'white',
            width: { xs: '100%', sm: '50%' },
            height:'5.5vh',
            marginBottom: '1rem',
            '& .MuiInputBase-root': {
              height: '5.5vh'
            },
          }}
        />
        <Button
          type="submit"
          variant="contained"
         
          sx={{ height: '5.5vh', width: {xs:'35%', sm:'20%'},borderRadius: 0, backgroundColor:'#2E9F7E'}}
        >
          Subscribe
        </Button>
      </Box>
    </Box>
  );
};

export default AlertSignup;