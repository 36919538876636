import React from 'react';
import { Typography, Paper, Box, Card, CardContent, Link, Grid } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import tokenData from '../types/token';
import BigNumber from 'bignumber.js';
import GppBadIcon from '@mui/icons-material/GppBad';
import GppGoodIcon  from "@mui/icons-material/GppGood";

interface HoldersAndLiquidityProps {
  tokenData: tokenData;
  chain: string;
  address: string;
}
interface Liquidity {
    id: string;
    liquidity: string;
    dex: string;
  }
  
  interface MergedLiquidity {
    [dex: string]: {
      liquidity: number;
      id: string;
    };
  }

  interface dexmap{
    [dex:string]:string
  }

const palette =  [
  '#3D0A40', // Darkest Purple
  '#580C61',
  '#730F82',
  '#8E11A3',
  '#A914C4',
  '#C516E5',
  '#D938FF',
  '#E668FF',
  '#F399FF',
  '#FFFFFF'  
];
const supportedDexes = [
'raydium', 'meteora', 'orca', 
'traderjoe', 'sushiswap-v2', 'stonfi', 
'pancakeswap_v3', 'pancakeswap_v2', 'uniswap_v3', 
'uniswap_v2', 'xswap', 'chewyswap', 
'marswap', 'aerodrome', 'thruster',
'velodrome','pulsex','dedust',
'nile','lynex','quickswap',
'zkswap', 'vvs'
]

const HoldersAndLiquidity: React.FC<HoldersAndLiquidityProps> = ({ tokenData, chain, address }) => {

function checkobj(obj:any){
  const checks = ['Pancake', 'Trader Joe', 'Uniswap', 'Sushiswap',]
  const deadaddress = "0x000000000000000000000000000000000000dead"
  checks.forEach((ob:any)=>{
  
    if (checks.includes(obj.address) || obj.address === deadaddress){
      return true
    }
  })

  return false
}

function formatlink(id: string, dex: string){
  const pair = id.split('_')[1]
  
  const map:dexmap = {
    'raydium': `https://raydium.io/swap/?ammId=${pair}`, 
    'meteora': `https://app.meteora.ag/pools/${pair}`, 
    'orca': `https://www.orca.so/?outputCurrency=${address}`,
    'traderjoe': `https://traderjoexyz.com/avalanche/pool/v1/${address}/AVAX`,
    'sushiswap-v2': (`https://www.sushi.com/swap?outputCurrency=${address}&token0=NATIVE&token1=${address}`),
    'stonfi': `https://app.ston.fi/swap?outputCurrency=${address}&chartVisible=false`,
    'pancakeswap_v2': `https://pancakeswap.finance/swap?outputCurrency=${address}`,
    'pancakeswap_v3': `https://pancakeswap.finance/swap?outputCurrency=${address}&chain=bsc`,
    'uniswap_v3': `https://app.uniswap.org/explore/tokens/${chain}/${address}`,
    'uniswap_v2': `https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=${address}`,
    'xswap' : `https://v3.xspswap.finance/#/tokens/xinfin/${address}`,
    'chewyswap': `https://chewyswap.dog/swap/?chain=shibarium&outputCurrency=${address}`,
    'marswap': `https://marswap.exchange/#/swap?outputCurrency=${address}`,
    'aerodrome': `https://aerodrome.finance/swap?from=0x4200000000000000000000000000000000000006&to=${address}`,
    'thruster-v3': `https://www.thruster.finance/?outputCurrency=${address}`,
    'quickswap_v3': `https://quickswap.exchange/#/swap?outputCurrency=${address}`,
    'lynex': `https://app.lynex.fi/swap?outputCurrency=${address}`,
    'nile': `https://www.nile.build/swap?outputCurrency=${address}`,
    'dedust': `https://dedust.io/swap/TON/${address}`,
    'pulsex': `https://pulsex.mypinata.cloud/ipfs/bafybeiesh56oijasgr7creubue6xt5anivxifrwd5a5argiz4orbed57qi/#/?outputCurrency=${address}`,
    'uniswap_v3_optimism': `https://app.uniswap.org/explore/tokens/${chain}/${address}`,
    'velodrome-finance-slipstream': `https://velodrome.finance/?outputCurrency=${address}`,
    'zkswap': `https://zkswap.finance/swap?outputCurrency=${address}`,
    'vvs': `https://vvs.finance/swap?outputCurrency=${address}`
    
  }

  return map[dex]

}

function analyzeHolders(holders: any[]): number[] {
  let flags = [0,0] 
  let total = 0

    for (const obj of holders) {
      console.log(obj)
      if ((parseFloat(obj.percent) > 0.1) && !checkobj(obj)) {
        flags[1] = 1
      }
      else{
        total += parseFloat(obj.percent)

      }
    }

    if (total > .329){
      flags[0] = 1
    }
    return flags
  }
  
  function analyzeBigHolders(holders: any[]): number[] {
    let flags = [0,0]
    let total = 0
    for (const obj of holders) {
      if (obj.label !== 'Radium AMM (LP)' && obj.label !== 'Meteora DLMM (LP)') {
        if (obj.value >= 10){
          flags[1] = 1
        }
        total += obj.value
      }
    }
    if (total > 32.9){
      flags[0] = 1
    }

    return flags
  }
  
  function totalLiquidity(arr:tokenData){
      let liq = 0
      for (let i = 0; i < arr.liquidity.length; i++) {
        liq += parseInt(arr.liquidity[i].liquidity)
      }
      return liq
  }
  function makeChart(holders: any[]): any[] {
    const final = holders.map((obj:any, i)=>{
      return {id: i, value: parseFloat(obj.percent) * 100, label: obj.address}
    })
    return final
  }
  
  function formatNumber(str:string) {
    const num = parseFloat(str);
    return isNaN(num) ? str : num.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }
  
  
 
    function getImage(dex: string) {
      const map: any = {
        'raydium': 'https://assets.coingecko.com/coins/images/13928/standard/PSigc4ie_400x400.jpg',
        'uniswap_v3': 'https://assets.coingecko.com/coins/images/12504/standard/uni.jpg?1696512319',
        'uniswap-v3': 'https://assets.coingecko.com/coins/images/12504/standard/uni.jpg?1696512319',
        'meteora': 'https://assets.coingecko.com/markets/images/1520/large/meteora.jpeg?1710908196',
        'orca': 'https://assets.coingecko.com/coins/images/17547/standard/Orca_Logo.png?1696517083',
        'pancakeswap_v2': 'https://assets.coingecko.com/markets/images/687/large/pancakeswap.jpeg?1706864634',
        'equalizer': 'https://assets.coingecko.com/coins/images/28231/standard/hq_png_icon_file.png?1696527232',
        'sushiswap-v3': 'https://assets.coingecko.com/markets/images/576/large/2048x2048_Logo.png?1706864530',
        'sushiswap-v2': 'https://assets.coingecko.com/markets/images/576/large/2048x2048_Logo.png?1706864530',
        'uniswap_v2': 'https://assets.coingecko.com/coins/images/12504/standard/uni.jpg?1696512319',
      };
    
      return ''// map[dex] || '';
    }
  
  
    const holdersWithPercentage = (topHolders: any[], totalSupplyString: string, decimals: number) => {
      const totalSupply = new BigNumber(totalSupplyString);
      const res = topHolders.map((holder, i) => {
        const amount = new BigNumber(holder.amount);
        const ratio = amount.dividedBy(totalSupply);
        const percentage = ratio.multipliedBy(new BigNumber(10).exponentiatedBy(decimals)).multipliedBy(100);
        return {
          id: i,
          value: percentage.toNumber(),
          label: holder.address === '5Q544fKrFoe6tsEbD7S8EmxGTJYAKtTVhAW5Q5pge4j1' ? "Radium AMM (LP)" : holder.address === 'HQpNDHYEfTTmRzNTKkGD91CcrrFFx7gqABsJfCjmXEyS' ? "Meteora DLMM (LP)" :holder.address,
          // Round to 6 decimal places
          percentageString: percentage.toFixed(decimals + 2)
        };
      });
      return res;
    };

    
    const sortitems = () => {
      const auditItems = tokenData && chain !== "solana" && tokenData.result && tokenData.result
  ? Object.entries({
    ownerPercentage:tokenData.result.owner_address !== undefined ? tokenData.result? tokenData.result?.owner_percent < 5 
      ? 'Owner does not hold a significant percentage of tokens' 
      : 'Owner holds a significant percentage of tokens'
      : ''
      : undefined,

    topHolders: tokenData.result.holders && tokenData.result.holders.length > 0? analyzeHolders(tokenData.result?.holders)[0] === 0 
      ? 'Top 10 do not hold a significant percentage of tokens' 
      : 'Top 10 hold a significant percentage of tokens'
      : '',

    liquidityPool: totalLiquidity(tokenData) < 10000 ? totalLiquidity(tokenData) < 100 ? 'Empty liquidity pool'  : 'Low liquidity pool' :'Liquidity pool is sufficient',

    significantHolder: tokenData.result.holders && tokenData.result.holders.length > 0?  analyzeHolders(tokenData.result?.holders)[1] === 0 
      ? 'No significant whales' 
      : 'Significant whale could have large price impact'
      : '',
  })
  .filter(([key, value]) => value !== undefined && value !== null && value !== '')
  .map(([key, value]) => ({
      key,
      value,
      isWarning: ['Avg. Liquidity ratio may be insufficient for amount of trades', 'Low liquidity pool', 'Significant whale could have large price impact'].includes(value!),
      isDanger: ['Empty liquidity pool','Owner holds a significant percentage of tokens', 'Top 10 hold a significant percentage of tokens'].includes(value!),
    })).filter(item => item.value !== '')
    .sort((a, b) => {
      if (a.isDanger && !b.isDanger) return -1;
      if (!a.isDanger && b.isDanger) return 1;
      if (a.isWarning && !b.isWarning) return -1;
      if (!a.isWarning && b.isWarning) return 1;
      return 0;
    })
  : tokenData && tokenData.solres
  ? Object.entries({
      ownerPercentage: (BigNumber(tokenData.solres.creatorBalance).dividedBy(BigNumber(tokenData.solres.supply))).multipliedBy(new BigNumber(10).exponentiatedBy(tokenData.solres.decimals)).multipliedBy(100).isLessThan(5) ? 'Owner does not hold a significant percentage of tokens' : 'Owner holds a significant percentage of tokens',
      topHolders: analyzeBigHolders(holdersWithPercentage(tokenData.solres.topHolders, tokenData.solres.supply, tokenData.solres.decimals))[0] === 0 ? 'Top 10 do not hold a significant percentage of tokens' : 'Top 10 hold a significant percentage of tokens',
      liquidityPool: totalLiquidity(tokenData) < 10000 ? totalLiquidity(tokenData) < 100 ? 'Empty liquidity pool'  : 'Low liquidity pool' :'Liquidity pool is sufficient',
      significantHolder: analyzeBigHolders(holdersWithPercentage(tokenData.solres.topHolders, tokenData.solres.supply, tokenData.solres.decimals))[1] === 0 ? 'No significant whales' : 'Significant whale could have large price impact',

    }).map(([key, value]) => ({
      key,
      value,
      isWarning: ['Avg. Liquidity ratio may be insufficient for amount of trades', 'Low liquidity pool', 'Significant whale could have large price impact'].includes(value),
      isDanger: ['Empty liquidity pool','Owner holds a significant percentage of tokens', 'Top 10 hold a significant percentage of tokens'].includes(value),
    })).sort((a, b) => {
      if (a.isDanger && !b.isDanger) return -1;
      if (!a.isDanger && b.isDanger) return 1;
      if (a.isWarning && !b.isWarning) return -1;
      if (!a.isWarning && b.isWarning) return 1;
      return 0;
    })
  : [];

  
    return auditItems

    }
  return (
    tokenData && (
    <Card sx={{width: {xs: '100vw', sm:'32.5vw'}, height: {xs: '75vh', sm: '52vh'}, backgroundColor: 'primary.dark', marginBottom: {xs: 0, sm: '1vh'}, marginTop: {xs:'1vh', sm: '0vh'}, borderRadius: {xs: 0, sm: 2}, marginLeft: {xs:'-19.9vw', sm:'1vh'}, overflowY:'auto' }}>
      <CardContent>
        <Typography variant='h5' sx={{fontWeight:'bold', fontSize:'1.5rem', textAlign:'left', marginLeft: '1vw', marginTop: '1vh',color:'white'}}>
          Holders and Liquidity 
        </Typography>
        {tokenData && chain !== 'solana' && tokenData.result ? 
  <div>
    {sortitems().map(({ key, value, isWarning, isDanger }) => (
      <Typography
        key={key}
        sx={{
          fontSize: '.9rem',
          fontWeight: 'bold',
          textAlign: 'left',
          marginLeft: '1vw',
          marginTop: '1vh',
          color: 'white',
        }}
      >
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {isDanger ? (
            <GppBadIcon sx={{ fontSize: '1.5rem', color: 'red', mr: 1 }} />
          ) : isWarning ? (
            <GppBadIcon sx={{ fontSize: '1.5rem', color: 'orange', mr: 1 }} />
          ) : (
            <GppGoodIcon sx={{ fontSize: '1.5rem', color: 'green', mr: 1 }} />
          )}
          {value}
        </span>
      </Typography>
    ))}
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
      <Paper
          sx={{
            backgroundColor: '#1C1D21',
            minHeight: '16vh',
            maxHeight: { xs: '30vh', md: '16vh' },
            width: '100%',
            overflowY: 'auto',
            border: '1px solid #ccc',
            padding: '1rem',
            marginTop: { xs: '2vh', sm: '1vh', md: '3vh'},
            marginLeft:{xs:'-4.5vw', sm:'-2vw', md: '0vw'}
          }}
        >
          <Typography
            component="div"
            sx={{ marginLeft:{xs:'-4.5vw', sm:'1vw'},fontSize: '1rem', fontWeight: 'bold', color: 'white', marginBottom: '1rem'}}
          >
            Liquidity Pools
          </Typography>
          {(() => {
            const mergedLiquidity = tokenData.liquidity.reduce((acc: MergedLiquidity, { liquidity, dex, id }: Liquidity) => {
              if (!acc[dex]) {
                acc[dex] = { liquidity: 0, id: id };
              }
              acc[dex].liquidity += parseFloat(liquidity);
              return acc;
            }, {} as MergedLiquidity);

            const mergedLiquidityArray = Object.entries(mergedLiquidity).map(([dex, { liquidity, id }]) => ({
              dex,
              liquidity,
              id,
            }));

            return mergedLiquidityArray.filter(({ liquidity }) => liquidity >= 300).map(({ dex, liquidity, id }) => (
              (supportedDexes.includes(dex) ? <Box key={dex}>
                <Typography sx={{ fontSize: '.9rem', fontWeight: 'bold', color: 'white' }}>
                <Link key={address} href={formatlink(id, dex)} target="_blank" rel="noopener noreferrer" color='secondary' sx ={{marginLeft:'.35vw', zIndex: 1}}>
                    ${formatNumber(liquidity.toString())} USD in {dex}
                  </Link>                  
                  {getImage(dex) !== '' && (
                    <img
                      src={getImage(dex)}
                      alt=""
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        flexShrink: 0,
                        marginLeft: '.1vw',
                        verticalAlign: 'middle',
                      }}
                      
                    />
                  )}
                </Typography>
              </Box>: <div></div>)
            ));
          })()}
        </Paper>
      </Grid>
      <Grid item xs={12} md={5}>
      <Paper
      sx={{
        backgroundColor: '#1C1D21',
        minHeight: '16vh',
        maxHeight: { xs: '30vh', md: '16vh' },
        width: '80%',
        border: '1px solid #ccc',
        padding: '1rem',
        marginTop: { xs: '2vh', sm: '1vh', md: '3vh' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft:{xs:'5vw', sm:'.5vw', md: '3vw'}
      }}
    >
      <Typography fontWeight="bold" sx={{ fontSize: '1rem', color: 'white', textAlign: 'center' }}>
        Top 10 Holders by %
      </Typography>
    <PieChart
      sx={{marginLeft:{xs:'7vw',sm:'1.25vw'}}}
      colors={palette}
      width={500}
      height={500}
      series={[
        {
          data: makeChart(tokenData.result.holders? tokenData.result.holders : []),
          highlightScope: { faded: 'global', highlighted: 'item' },
          innerRadius: '75%',
        },
      ]}
      slotProps={{ legend: { hidden: true } }}
    />
    </Paper>
      </Grid>
    </Grid>
  </div> : null}

{tokenData && tokenData.solres ? (
  <div>
    {sortitems().map(({ key, value, isWarning, isDanger }) => (
      <Typography
        key={key}
        sx={{
          fontSize: '.9rem',
          fontWeight: 'bold',
          textAlign: 'left',
          marginLeft: '1vw',
          marginTop: '1vh',
          color: 'white',
        }}
      >
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {isDanger ? (
            <GppBadIcon sx={{ fontSize: '1.5rem', color: 'red', mr: 1 }} />
          ) : isWarning ? (
            <GppBadIcon sx={{ fontSize: '1.5rem', color: 'orange', mr: 1 }} />
          ) : (
            <GppGoodIcon sx={{ fontSize: '1.5rem', color: 'green', mr: 1 }} />
          )}
          {value}
        </span>
      </Typography>
    ))}
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Paper
          sx={{
            backgroundColor: '#1C1D21',
            minHeight: '16vh',
            maxHeight: { xs: '30vh', md: '16vh' },
            width: '100%',
            overflowY: 'auto',
            border: '1px solid #ccc',
            padding: '1rem',
            marginTop: { xs: '2vh', sm: '1vh', md: '3vh'},
            marginLeft:{xs:'-4.5vw', sm:'-2vw', md: '0vw'}

          }}
        >
          <Typography
            component="div"
            sx={{ fontSize: '1rem', fontWeight: 'bold', color: 'white', marginBottom: '1rem'}}
          >
            Liquidity Pools
          </Typography>
          {(() => {
            const mergedLiquidity = tokenData.liquidity.reduce((acc: MergedLiquidity, { liquidity, dex, id }: Liquidity) => {
              if (!acc[dex]) {
                acc[dex] = { liquidity: 0, id: id };
              }
              acc[dex].liquidity += parseFloat(liquidity);
              return acc;
            }, {} as MergedLiquidity);

            const mergedLiquidityArray = Object.entries(mergedLiquidity).map(([dex, { liquidity, id }]) => ({
              dex,
              liquidity,
              id,
            }));

            return mergedLiquidityArray.filter(({ liquidity }) => liquidity >= 300).map(({ dex, liquidity, id }) => (
              (supportedDexes.includes(dex) ? <Box key={dex}>
                <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', color: 'white' }}>
                <Link key={address} href={formatlink(id, dex)} target="_blank" rel="noopener noreferrer" color='secondary' sx ={{marginLeft:'.35vw', zIndex: 1}}>
                    ${formatNumber(liquidity.toString())} USD in {dex}
                  </Link>                  
                  {getImage(dex) !== '' && (
                    <img
                      src={getImage(dex)}
                      alt=""
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        flexShrink: 0,
                        marginLeft: '.1vw',
                        verticalAlign: 'middle',
                      }}
                    />
                  )}
                </Typography>
              </Box> : <div></div>)
            ));
          })()}
        </Paper>
      </Grid>
      <Grid item xs={12} md={5}>
      <Paper
      sx={{
        backgroundColor: '#1C1D21',
        minHeight: '16vh',
        maxHeight: { xs: '30vh', md: '16vh' },
        width: '80%',
        border: '1px solid #ccc',
        padding: '1rem',
        marginTop: { xs: '2vh', sm: '1vh', md: '3vh' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft:{xs:'5vw', sm:'.5vw', md: '3vw'}

      }}
    >
      <Typography fontWeight="bold" sx={{ fontSize: '1rem', color: 'white', textAlign: 'center' }}>
        Top 10 Holders by %
      </Typography>
    <PieChart
      sx={{marginLeft:{xs:'7vw',sm:'1.25vw'}}}
      colors={palette}
      width={500}
      height={500}
      series={[
        {
          data: holdersWithPercentage(tokenData.solres.topHolders, tokenData.solres.supply, tokenData.solres.decimals),
          highlightScope: { faded: 'global', highlighted: 'item' },
          innerRadius: '75%',
        },
      ]}
      slotProps={{ legend: { hidden: true } }}
    />
    </Paper>
      </Grid>
    </Grid>
  </div>
) : null}
      </CardContent>
    </Card>
    )
  );
};

export default HoldersAndLiquidity;